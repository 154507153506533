import "./App.css";
import { useEffect, useRef } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import Homepage from "./pages/Homepage/Homepage";
import Navbar from "./components/header/WebNavbar";
import MobileNavbar from "./components/header/MobileNavbar";
import Footer from "./components/footer/Footer";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactForm from "./pages/ContactForm";

function App() {
  const targetRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lng } = useParams(); // Get language from the URL params

  const scrollToTarget = () => {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Sync i18n language with the URL language
  useEffect(() => {
    if (lng && i18n.language !== lng) {
      i18n.changeLanguage(lng); // Set the language in i18n
      document.documentElement.dir = lng === "ar" ? "rtl" : "ltr"; // Set text direction
    }
  }, [lng]);

  const currentLanguage = i18n.language; // Get the current language from i18n instance

  // Set root element's direction based on language
  document.documentElement.dir = currentLanguage === "ar" ? "rtl" : "ltr";

  // Function to set language preference in local storage
  const setLanguagePreference = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng); // Change i18n language
    navigate(`/${lng}`); // Update the URL with the language
  };

  return (
    <div className="App">
      <Navbar
        scrollToTarget={scrollToTarget}
        setLanguagePreference={setLanguagePreference}
      />
      <MobileNavbar setLanguagePreference={setLanguagePreference} />
      <Routes>
        <Route path="/:lng" element={<Homepage targetRef={targetRef} />} />
        <Route path="/:lng/terms-conditions" element={<TermsConditions />} />
        <Route path="/:lng/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/:lng/contact-us" element={<ContactForm />} />
        <Route path="*" element={<Homepage targetRef={targetRef} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
