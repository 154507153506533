import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useLocation } from "react-router-dom"; // React Router to extract the URL
import translationEN from "./locales/en/translation.json";
import translationAR from "./locales/ar/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};

// Helper function to get the language from the URL
const getCurrentLanguage = () => {
  const path = window.location.pathname.split("/")[1]; // Get the first part of the URL (e.g., 'en' or 'ar')
  return path === "ar" || path === "en" ? path : "ar"; // Default to 'ar' if no valid language is found
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getCurrentLanguage(), // Set language from URL
    fallbackLng: "en", // fallback language
    interpolation: {
      escapeValue: false, // not needed for React
    },
    detection: {
      order: ["path", "localStorage", "cookie"], // Detect language from the path first, then local storage or cookie
    },
  });

export default i18n;
