import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import heroImg from "../../assets/new-hero-img.png";

function SectionOne() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="flex flex-col-reverse lg:flex-row justify-between items-center px-4 lg:px-16 pt-10 lg:h-screen">
      <div
        id="text-box"
        className="text-center lg:text-start lg:pr-8 lg:mt-14 max-w-[90%] lg:max-w-[75%] lg:w-1/2"
        data-aos="fade-right"
        data-aos-duration="900"
        data-aos-once="true"
      >
        <h1
          className="text-[40px] lg:text-[66px] capitalize leading-[42px] lg:leading-[64px] text-[#00001c] font-semibold tracking-[-1.96px] lg:mb-[0.2em] pb-3 lg:pb-5"
          style={{ fontFamily: "Tajawal, Sans-serif" }}
        >
          {t("sectionOneH1")}
        </h1>
        <h2
          className="text-[16px] text-justify lg:text-[24px] leading-[1.2em] text-[#00001c] font-normal tracking-tighter mt-[1em] mb-[0.35em] lg:my-[0.35em]"
          style={{ fontFamily: "Tajawal, Sans-serif" }}
        >
          {t("sectionOneH2One")}
        </h2>
        <h2
          className="text-[16px] text-justify lg:text-[24px] leading-[1.2em] text-[#00001c] font-normal tracking-tighter mb-[1em] mt-[0.35em] lg:my-[0.35em]"
          style={{ fontFamily: "Tajawal, Sans-serif" }}
        >
          {t("sectionOneH2Two")}
        </h2>
      </div>
      <div
        id="img-box"
        className="flex w-1/2 flex-row justify-center items-center my-8 lg:mt-0"
        data-aos="fade-up"
        data-aos-duration="900"
        data-aos-once="true"
      >
        <img
          fetchpriority="high"
          src={heroImg}
          alt="rize-rent-now-pay-later"
          className="w-[60%] lg:w-[30%] m-2"
        />
      </div>
    </div>
  );
}

export default SectionOne;
